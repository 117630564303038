import * as React from 'react';

export type ItemProps = {
    isAvailable: boolean,
}

const Item: React.FC<ItemProps> = ({ isAvailable, children }) => {
    return <>{children}</>;
}

export default Item;