import * as React from 'react';

type ConditionProps = {
    is: () => boolean,
}

const Condition: React.FC<ConditionProps> = ({ is, children }) => {
    return is() ? <>{children}</> : null;
}

export default Condition;
