import { DocumentNode } from 'graphql';

export type FieldKind =
    | 'NON_NULL'
    | 'SCALAR'
    | 'OBJECT'
    | 'INPUT_OBJECT'
    | 'INTERFACE'
    | 'LIST'
    | null;
export type FieldName = 'ID' | 'Float' | 'Int' | 'String' | 'OBJECT' | 'Boolean' | null;

export type EntityTypeField = {
    name: string; //"id",
    description: string | null; //null,
    type: {
        kind: FieldKind;
        name: FieldName;
        ofType: null | {
            kind: FieldKind;
            name: FieldName;
        };
    };
};

export declare type EntityType = {
    kind: 'INTERFACE';
    name: string;
    possibleTypes: EntityType[] | null;
    description: string;
    fields: EntityTypeField[];
    inputFields: EntityTypeField[];
};

export declare type Collection<T> = {
    edges: Array<Node<T>>;
    totalCount: number;
    pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
    };
};

export declare type Node<T> = {
    node: T;
    cursor: string;
    __typename: string;
};

export declare type BaseEntity<T> = T & {
    id: string;
    __typename: string;
};

export enum ApproveCredit {
    declined = 'approve_credit_declined',
    onHold = 'approve_credit_on_hold',
    canceled = 'approve_credit_canceled',
    confirmed = 'approve_credit_confirmed',
}

export enum DirectCredit {
    declined = 'direct_credit_declined',
    confirmed = 'direct_credit_confirmed',
}

export enum Debit {
    confirmed = 'debit_confirmed',
}

export enum SortingOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export type TransactionState =
    | Debit.confirmed
    | DirectCredit.confirmed
    | DirectCredit.declined
    | ApproveCredit.declined
    | ApproveCredit.canceled
    | ApproveCredit.confirmed
    | ApproveCredit.onHold;

export type Entity = {
    fragment: DocumentNode;
    createId: (id: string) => string;
};

export type TOrder = 'asc' | 'desc' | undefined;
export type OrderArray<T extends string> = Partial<Record<T, TOrder>>[];

export type TResponseViolation = { path: string; message: string };

export type TResponseExtensons = {
    category: string;
    status: number;
    violations: TResponseViolation[];
};

export type UserRole =
    | 'ROLE_USER'
    | 'ROLE_ADMIN'
    | 'ROLE_CLIENT'
    | 'ANONYMOUS_AGGREGATOR_CLIENT'
    | 'ROLE_AGGREGATOR_USER'
    | 'ROLE_OWNER';

export enum UserRoles {
    User = 'ROLE_USER',
    Admin = 'ROLE_ADMIN',
    Client = 'ROLE_CLIENT',
    AnonymousAggregatorClient = 'ANONYMOUS_AGGREGATOR_CLIENT',
    AggregatorUser = 'ROLE_AGGREGATOR_USER',
    Owner = 'ROLE_OWNER',
    Courier = 'ROLE_COURIER',
}
