import * as React from 'react';

import Default from './Default';
import Item, { ItemProps } from './Item';

const Fallback: React.FC<{ children: React.ReactElement<ItemProps, typeof Item>[] }> & { Item: React.ComponentType<ItemProps>, Default: React.ComponentType } = ({ children }) => {
    const availableChildren = children
        .filter(child => child.props.isAvailable && child.type.name !== 'Default');

    const defaultComponent = children
        .find(child => child.type.name === 'Default'); 

    const returnableChildren = <>{availableChildren}</>;
    const returnableDefaultComponent = defaultComponent ? <>{defaultComponent}</> : null;

    return availableChildren.length > 0
        ? returnableChildren
        : returnableDefaultComponent;
}

Fallback.Item = Item;
Fallback.Default = Default;

export default Fallback;
