import React, { forwardRef } from 'react';

import { default as SearchOutlinedBase } from '@ant-design/icons-svg/es/asn/SearchOutlined';
import { default as CloseCircleOutlinedBase } from '@ant-design/icons-svg/es/asn/CloseCircleOutlined';
import { default as PlusOutlinedBase } from '@ant-design/icons-svg/es/asn/PlusOutlined';
import { default as WarningTwoToneBase } from '@ant-design/icons-svg/es/asn/WarningTwoTone';
import { default as SyncOutlinedBase } from '@ant-design/icons-svg/es/asn/SyncOutlined';
import { default as DeleteOutlinedBase } from '@ant-design/icons-svg/es/asn/DeleteOutlined';
import { default as ExclamationCircleTwoToneBase } from '@ant-design/icons-svg/es/asn/ExclamationCircleTwoTone';
import { default as InfoCircleOutlinedBase } from '@ant-design/icons-svg/es/asn/InfoCircleOutlined';
import { default as HistoryOutlinedBase } from '@ant-design/icons-svg/es/asn/HistoryOutlined';
import { default as LinkOutlinedBase } from '@ant-design/icons-svg/es/asn/LinkOutlined';
import { default as UserOutlinedBase } from '@ant-design/icons-svg/es/asn/UserOutlined';
import { default as EnvironmentOutlinedBase } from '@ant-design/icons-svg/es/asn/EnvironmentOutlined';
import { default as LoadingOutlinedBase } from '@ant-design/icons-svg/es/asn/LoadingOutlined';
import { default as MoreOutlinedBase } from '@ant-design/icons-svg/es/asn/MoreOutlined';
import { default as EditOutlinedBase } from '@ant-design/icons-svg/es/asn/EditOutlined';
import { default as DownOutlinedBase } from '@ant-design/icons-svg/es/asn/DownOutlined';
import { default as SortDescendingOutlinedBase } from '@ant-design/icons-svg/es/asn/SortDescendingOutlined';
import { default as SortAscendingOutlinedBase } from '@ant-design/icons-svg/es/asn/SortAscendingOutlined';
import { default as CaretDownOutlinedBase } from '@ant-design/icons-svg/es/asn/CaretDownOutlined';
import { default as PlusCircleOutlinedBase } from '@ant-design/icons-svg/es/asn/PlusCircleOutlined';
import { default as CreditCardFilledBase } from '@ant-design/icons-svg/es/asn/CreditCardFilled';
import { default as CheckOutlinedBase } from '@ant-design/icons-svg/es/asn/CheckOutlined';
import { default as CopyOutlinedBase } from '@ant-design/icons-svg/es/asn/CopyOutlined';
import { default as AndroidFilledBase } from '@ant-design/icons-svg/es/asn/AndroidFilled';
import { default as AppleFilledBase } from '@ant-design/icons-svg/es/asn/AppleFilled';
import { default as MailOutlinedBase } from '@ant-design/icons-svg/es/asn/MailOutlined';
import { default as PhoneOutlinedBase } from '@ant-design/icons-svg/es/asn/PhoneOutlined';
import { default as ClockCircleOutlinedBase } from '@ant-design/icons-svg/es/asn/ClockCircleOutlined';
import { default as QuestionCircleFilledBase } from '@ant-design/icons-svg/es/asn/QuestionCircleFilled';
import { default as CheckCircleOutlinedBase } from '@ant-design/icons-svg/es/asn/CheckCircleOutlined';

import { IconDefinition } from '@ant-design/icons-svg/es/types';

import AntSvgIcon, { AntSvgIconProps } from './components/icon/AntSvgIcon';

type IconProps = Partial<AntSvgIconProps>;

const createIcon = (icon: IconDefinition) =>
    forwardRef<HTMLSpanElement, IconProps>((props: IconProps, ref) => (
        <AntSvgIcon ref={ref} icon={icon} {...props} />
    ));

const SearchOutlined = createIcon(SearchOutlinedBase);
const AndroidFilled = createIcon(AndroidFilledBase);
const AppleFilled = createIcon(AppleFilledBase);
const CopyOutlined = createIcon(CopyOutlinedBase);
const MailOutlined = createIcon(MailOutlinedBase);
const PhoneOutlined = createIcon(PhoneOutlinedBase);
const CheckCircleOutlined = createIcon(CheckCircleOutlinedBase);
const ClockCircleOutlined = createIcon(ClockCircleOutlinedBase);
const QuestionCircleFilled = createIcon(QuestionCircleFilledBase);
const CreditCardFilled = createIcon(CreditCardFilledBase);
const CheckOutlined = createIcon(CheckOutlinedBase);
const CloseCircleOutlined = createIcon(CloseCircleOutlinedBase);
const PlusOutlined = createIcon(PlusOutlinedBase);
const WarningTwoTone = createIcon(WarningTwoToneBase);
const SyncOutlined = createIcon(SyncOutlinedBase);
const DeleteOutlined = createIcon(DeleteOutlinedBase);
const LinkOutlined = createIcon(LinkOutlinedBase);
const UserOutlined = createIcon(UserOutlinedBase);
const MoreOutlined = createIcon(MoreOutlinedBase);
const EditOutlined = createIcon(EditOutlinedBase);
const ExclamationCircleTwoTone = createIcon(ExclamationCircleTwoToneBase);
const InfoCircleOutlined = createIcon(InfoCircleOutlinedBase);
const HistoryOutlined = createIcon(HistoryOutlinedBase);
const EnvironmentOutlined = createIcon(EnvironmentOutlinedBase);
const LoadingOutlined = createIcon(LoadingOutlinedBase);
const DownOutlined = createIcon(DownOutlinedBase);
const PlusCircleOutlined = createIcon(PlusCircleOutlinedBase);
const SortDescendingOutlined = createIcon(SortDescendingOutlinedBase);
const SortAscendingOutlined = createIcon(SortAscendingOutlinedBase);
const CaretDownOutlined = createIcon(CaretDownOutlinedBase);

export {
    SearchOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    WarningTwoTone,
    SyncOutlined,
    DeleteOutlined,
    ExclamationCircleTwoTone,
    InfoCircleOutlined,
    HistoryOutlined,
    LinkOutlined,
    UserOutlined,
    EnvironmentOutlined,
    LoadingOutlined,
    MoreOutlined,
    EditOutlined,
    DownOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
    CaretDownOutlined,
    PlusCircleOutlined,
    CreditCardFilled,
    CheckOutlined,
    CopyOutlined,
    AndroidFilled,
    AppleFilled,
    PhoneOutlined,
    MailOutlined,
    ClockCircleOutlined,
    QuestionCircleFilled,
    CheckCircleOutlined,
};
