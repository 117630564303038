import React, { useState } from 'react';

// @ts-ignore has no typings
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Spin } from 'antd';

import View from '../../../View';
import { LoadingOutlined } from '../../../../icons';

export type PayPalButtonProps = {
    onApprove?: (data: any, actions: any) => void;
    onCancel?: (data: any, actions: any) => void;
    onError?: (data: any, actions: any) => void;
    onInit?: (data: any, actions: any) => void;
    createOrder?: (data: any, actions: any) => void;
    clientId: string;
    currency: string;
    disabled?: boolean;
    containerStyles?: React.CSSProperties;
};

const PayPalButton: React.FC<PayPalButtonProps> = React.memo(
    ({ currency, clientId, disabled, containerStyles, onInit, ...buttonProps }) => {
        const [wasInit, setInit] = useState(false);

        const isButtonDisabled = disabled || !currency || !clientId;
        const isButtonPreparing = !disabled && (!currency || !clientId);

        const buttonHeight = 40;

        const indicator = <LoadingOutlined />;

        return (
            <>
                {isButtonPreparing && (
                    <Spin spinning={true} indicator={indicator}>
                        <View style={{ height: buttonHeight }} />
                    </Spin>
                )}
                {!isButtonDisabled && (
                    <Spin
                        spinning={!wasInit}
                        style={{ height: buttonHeight }}
                        indicator={indicator}
                    >
                        <PayPalScriptProvider options={{ 'client-id': clientId, currency }}>
                            <div style={containerStyles}>
                                <PayPalButtons
                                    style={{
                                        layout: 'horizontal',
                                        color: 'blue',
                                        shape: 'pill',
                                        tagline: false,
                                        height: buttonHeight,
                                    }}
                                    {...buttonProps}
                                    onInit={(data: any, actions: any) => {
                                        setInit(true);
                                        onInit && onInit(data, actions);
                                    }}
                                />
                            </div>
                        </PayPalScriptProvider>
                    </Spin>
                )}
            </>
        );
    },
);

export default PayPalButton;
