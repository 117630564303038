import React from 'react';
import './App.css';

import { Router, Switch, Route } from "react-router-dom"
import { createBrowserHistory } from 'history'
import 'url-search-params-polyfill';

import Pay from './routes/pay';
import Page404 from "./aqua-delivery-web-client-ui/components/static-pages/Page404"
import ErrorBoundary from './aqua-delivery-web-client-ui/components/ErrorBoundary';
import I18n, { I18nSetConfig, I18nContext } from './aqua-delivery-web-client-ui/i18n';
import translations from './translations';

import './aqua-delivery-web-client-ui/assets/styles/common.less'

const history = createBrowserHistory()

I18nSetConfig({
    translations,
});

const App: React.FC = () => {
    React.useEffect(() => {
        window.document.title = "Aqua Delivery"
    }, [])

    const [locale, setLocale] = React.useState(I18n.locale)

    return (
        <I18nContext.Provider value={{
            locale,
            setLocale: (locale) => {
                I18nSetConfig({ locale });
                setLocale(locale);
            }
        }}>
            <Router history={history}>
                <ErrorBoundary>
                    <Switch>
                        <Route history={history} path='/pay/:invoice' component={Pay} exact />
                        <Route component={Page404} />

                    </Switch>
                </ErrorBoundary>

            </Router>
        </I18nContext.Provider>
    );
}

export default App;
