import I18n from "./aqua-delivery-web-client-ui/i18n";

export type ServiceName = "invoice" | "auth"

type Api<T> = T & {
    domain: string,
    base: string,
    enrichToken?: string,
}

type ConfigApp = {
    version: string,
    api: {
        auth: Api<{
            getToken: string,
            refreshTokenUrl: string
        }>,
        invoice: Api<{
            invoiceDetails: string
        }>,
    }
    tokenStoreKey: string,
    refreshTokenStoreKey: string,
    cloudPaymentsPublicId: string,
    partnerWebclientUrl: string,
    paymentMethodsAvailability: {
        isCloudPaymentEnabled: boolean,
        isPayPalEnabled: boolean,
    },
    payPalClientId: string;
}

const devAuthBaseUrlStoreKey = "auth_service_url";
const devPartnerBaseUrlStoreKey = "partner_service_url";

export function getEnvVariable(key: string) {
    //@ts-ignore
    const env = window.env;
    const locale = env[I18n.locale] || env[I18n.defaultLocale];

    if (locale && locale[key]) return locale[key];

    throw Error(`You need to add key ${key} into env.js`);
}

function getBaseUrl(localStorageKey: string, envKey: string){
    return localStorage.getItem(localStorageKey) || getEnvVariable(envKey);
}

let configBase: ConfigApp | undefined = undefined; 

export function initConfig() {
    const baseAuthApi = getBaseUrl(devAuthBaseUrlStoreKey, "authServiceUrl");
    const baseInvoiceApi = getBaseUrl(devPartnerBaseUrlStoreKey, "invoiceServiceUrl");
    const partnerWebclientUrl = getEnvVariable("partnerWebclientUrl");

    configBase = {
        version: "0.0.1",
        tokenStoreKey: 'token',
        refreshTokenStoreKey: 'refresh_token',
        api: {
            auth: {
                domain: baseAuthApi,
                base: `${baseAuthApi}/auth/graphql` ,
                getToken: `${baseAuthApi}/auth/login/token/get`,
                refreshTokenUrl: `${baseAuthApi}/auth/login/token/refresh`
            },
            invoice: {
                domain: baseInvoiceApi,
                base: `${baseInvoiceApi}/invoice/graphql` ,
                invoiceDetails: `${baseInvoiceApi}/invoice/invoice_details_requests`
            }
        },
        cloudPaymentsPublicId:  getEnvVariable("cloudPaymentToken"),
        partnerWebclientUrl,
        paymentMethodsAvailability: getEnvVariable("paymentMethodsAvailability"),
        payPalClientId: getEnvVariable('payPalClientId'),
    }
}

export const config = {
    get version() { return configBase?.version },
    get tokenStoreKey() { return configBase!.tokenStoreKey },
    get refreshTokenStoreKey() { return configBase!.refreshTokenStoreKey },
    get cloudPaymentsPublicId() { return configBase!.cloudPaymentsPublicId },
    get api() { return configBase!.api },
    get partnerWebclientUrl() { return configBase!.partnerWebclientUrl },
    get paymentMethodsAvailability() { return configBase!.paymentMethodsAvailability },
    get payPalClientId() {return configBase!.payPalClientId},
}