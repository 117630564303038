export default {
    ui: {
        selectorLang: {
            label: "Язык"
        }
    },
    payPage: {
        statuses: {
            success: "Успешно",
        },
        successPayment: "Услуга {{serviceName}} оплачена",
        goToCabinet: "Перейти в кабинет",
        pageTitle: "Оплата услуги",
        main: {
            particles: {
                weAreGetYourRequest: "Мы получили от вас запрос",
                forService: "на услугу «{{serviceTitle}}»",
                servicePrice: "Стоимость услуги",
            }
        },
        pay: "Оплатить",
    },
}