import ru from "./ru";
import en from "./en";
import { createTranslationMap } from "../aqua-delivery-web-client-ui/i18n";

export const translateMap:typeof ru = createTranslationMap(ru)

export default {
    ru,
    en
}
