import React, { ReactNode } from 'react';

import Fallback from '../../fallback/Fallback';
import CloudPaymentsButton, { CloudPaymentsButtonProps } from './payment-buttons/CloudPaymentsButton';
import PayPalButton, { PayPalButtonProps } from './payment-buttons/PayPalButton';

import useCloudPayments from '../hooks/useCloudPayments';
import usePayPal from '../hooks/usePayPal';

type PaymentButtonsProps = {
    cloudPaymentsProps: CloudPaymentsButtonProps | undefined;
    payPalProps: PayPalButtonProps | undefined;
    paymentMethodsAvailability: {
        isPayPalEnabled: boolean;
        isCloudPaymentEnabled: boolean;
    }

    emptyPaymentMethodsComponent?: ReactNode;
};

const PaymentButtons: React.FC<PaymentButtonsProps> = ({
    cloudPaymentsProps,
    payPalProps,
    emptyPaymentMethodsComponent,
    paymentMethodsAvailability,
}) => {
    const { isSuitableCurrency: payPal } = usePayPal({
        currency: payPalProps?.currency,
        checkCurrency: !!payPalProps,
    });
    const { isSuitableCurrency: cloudPayments } = useCloudPayments({
        currency: cloudPaymentsProps?.chargeInfo.currency,
        checkCurrency: true,
    });

    const isPayPalEnabled = paymentMethodsAvailability.isPayPalEnabled;
    const isCloudPaymentEnabled = paymentMethodsAvailability.isCloudPaymentEnabled;

    const isCloudPaymentsAvailable = cloudPayments && isCloudPaymentEnabled && !!cloudPaymentsProps;

    const isPayPalAvailable = isPayPalEnabled && payPal;

    return (
        <Fallback>
            <Fallback.Item isAvailable={isPayPalAvailable}>
                <PayPalButton {...payPalProps!} disabled={!isPayPalEnabled || !payPal} />
            </Fallback.Item>
            <Fallback.Item isAvailable={isCloudPaymentsAvailable}>
                <CloudPaymentsButton
                    {...cloudPaymentsProps!}
                    disabled={!isCloudPaymentsAvailable}
                />
            </Fallback.Item>
            <Fallback.Default>{emptyPaymentMethodsComponent}</Fallback.Default>
        </Fallback>
    );
};

export default PaymentButtons;
