import React, { useCallback, useMemo } from 'react';

import { Button } from 'antd';

import Condition from '../../../Condition';
import { t } from '../../../../i18n';
import { CreditCardFilled } from '../../../../icons';

export type CloudPaymentsButtonProps = {
    email: string;
    items: PaymentItem[];
    onClick?: () => void;
    onSuccess: (options: any) => Promise<void>;
    onError: (reason: string | null, options: any) => Promise<void>;
    language: string;
    disabled?: boolean;
    chargeInfo: {
        accountId: string;
        currency: string;
        publicId: string;
        description: string;
        skin?: string;
    };
};

export type PaymentItem = {
    label: string;
    price: number;
    quantity: number;
    amount: number;
    vat: number;
    method: number;
    object: number;
};

const CloudPaymentsButton: React.FC<CloudPaymentsButtonProps> = props => {
    const { items, email, chargeInfo, onSuccess, onError, onClick, language, disabled } = props;

    const chargeAmount = useMemo(() => items.reduce((sum, item) => sum + item.amount, 0), [items]);

    const pay = useCallback(async () => {
        const receipt = {
            Items: items,
            email,
        };

        const data = {
            cloudPayments: {
                customerReceipt: receipt,
            },
        };

        onClick && onClick();

        //@ts-ignore
        const widget = new cp.CloudPayments({ language });

        widget.charge(
            {
                ...chargeInfo,
                amount: chargeAmount,
                data,
            },
            onSuccess,
            onError,
        );
    }, [items, email, onClick, language, chargeInfo, chargeAmount, onSuccess, onError]);

    return (
        <Condition is={() => !disabled}>
            <Button
                onClick={pay}
                type="primary"
                shape="round"
                size="large"
                style={{ height: 40, fontSize: 16, width: '100%' }}
            >
                <CreditCardFilled /> {t('ui.cloudPaymentsButton.label')}
            </Button>
        </Condition>
    );
};

export default CloudPaymentsButton;
