import * as React from 'react';
import { Menu, Dropdown, Typography } from 'antd';
import { I18nContext, t } from '../i18n';
import { setNativePropsByTag } from '../Utils';
import { DownOutlined } from '../icons';

const locales: Record<string, string> = {
    ru: 'Русский',
    en: 'English',
};

const SelectorLanguage: React.FC = () => {
    const ref = React.useRef<any>(null);
    const { locale, setLocale } = React.useContext(I18nContext);
    const [placement, setPlacement] = React.useState<'bottomRight' | 'topLeft'>('topLeft');
    //return null

    const setLang = (lng: string) => {
        setNativePropsByTag('head', head => {
            const css = `.lang {
                position:absolute;
                width:100%;
                height:100%;
                z-index:100;
                background: white;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }`;
            const style = document.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            style.appendChild(document.createTextNode(css));
        });

        setNativePropsByTag('body', el => {
            const text = document.createElement('div');
            text.innerHTML = 'Setting language...';
            const elem = document.createElement('div');
            elem.className = 'lang fadeIn';
            elem.appendChild(text);
            document.body.appendChild(elem);
        });
        setLocale(lng);
        window.location.reload();
    };

    React.useEffect(() => {
        if (ref.current) {
            setPlacement(ref.current.getBoundingClientRect().top < 100 ? 'bottomRight' : 'topLeft');
        }
    }, []);

    return (
        <Dropdown
            overlay={
                <Menu>
                    {Object.keys(locales).map(item => {
                        return (
                            <Menu.Item
                                key={item}
                                onClick={() => {
                                    if (locale !== item) {
                                        setLang(item);
                                    }
                                }}
                            >
                                {locales[item]}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            }
            placement={placement}
        >
            <span ref={ref}>
                <Typography.Text>{t('ui.selectorLang.label')}: </Typography.Text>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {locales[locale] || 'English'} <DownOutlined />
                </a>
            </span>
        </Dropdown>
    );
};

export default SelectorLanguage;
