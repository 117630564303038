import { EntityType } from "./types";
import { gql } from "apollo-boost";
import { lcFirst } from "./utils";

export type TScheme = {
  __schema: {
    types: any
  }
}

export type GenerateMutationOptions = {
  excludeFields?: string[],
  excludePayloadFields?: string[]
}

export type MutationType = "create" | "update" | "delete" | "add";

export default class Scheme {

  static get introspectionQuery() {
    return {
      variables: {},
      operationName: 'IntrospectionQuery',
      query: `query IntrospectionQuery {
                __schema {
                  queryType { name }
                  mutationType { name }
                  subscriptionType { name }
                  types {
                    ...FullType
                  }
                  directives {
                    name
                    description
                    locations
                    args {
                      ...InputValue
                    }
                  }
                }
              }
            
              fragment FullType on __Type {
                kind
                name
                description
                fields(includeDeprecated: true) {
                  name
                  description
                  args {
                    ...InputValue
                  }
                  type {
                    ...TypeRef
                  }
                  isDeprecated
                  deprecationReason
                }
                inputFields {
                  ...InputValue
                }
                interfaces {
                  ...TypeRef
                }
                enumValues(includeDeprecated: true) {
                  name
                  description
                  isDeprecated
                  deprecationReason
                }
                possibleTypes {
                  ...TypeRef
                  fields(includeDeprecated: true) {
                      name
                      description
                      args {
                        ...InputValue
                      }
                      type {
                        ...TypeRef
                      }
                      isDeprecated
                      deprecationReason
                    }
                }
              }
            
              fragment InputValue on __InputValue {
                name
                description
                type { ...TypeRef }
                defaultValue
              }
            
              fragment TypeRef on __Type {
                kind
                name
                description
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                      ofType {
                        kind
                        name
                        ofType {
                          kind
                          name
                          ofType {
                            kind
                            name
                            ofType {
                              kind
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
    }
  }

  private _scheme: any;
  private _fragmentTypes: any;

  constructor(sheme: { data: TScheme } | undefined = undefined) {
    if (!sheme) return;
    if(sheme && !sheme.data){
      //console.log(sheme);
    }
    // // here we're filtering out any type information unrelated to unions or interfaces
    const filteredData = sheme.data.__schema.types.filter(
      (type: any) => type.possibleTypes !== null,
    );
    const apolloFragments = {
      __schema: {
        types: filteredData
      }
    }

    this._fragmentTypes = apolloFragments
    this._scheme = sheme.data
  }

  public get fullScheme() {
    return this._scheme
  }

  public get fragmentTypes() {
    return this._fragmentTypes
  }

  public getEntityType(name: string): EntityType | undefined {
    const scheme = this.fullScheme;
    const type: EntityType = scheme.__schema.types.find((type: EntityType) => type.name === name);
    if (!type) {
      const Node = this.getEntityType("Node");
      if (!Node || !Node.possibleTypes) return undefined
      const type = Node.possibleTypes.find((type: EntityType) => type.name === name);
      if (type) return type
    } else {
      return type
    }
  }

  public getMutation(name: string, mutationType: MutationType = "create") {
    return this.getEntityType(`${mutationType}${name}Input`)
  }

  public getPayload(name: string, mutationType: MutationType = "create") {
    return this.getEntityType(`${mutationType}${name}Payload`)
  }

  public getPossibleTypes(name: string): EntityType[] | null {

    const type = this.getEntityType(name)
    //console.log(type)
    //нашли интересующий нас тип и теперь прошерстим его и для каждого интерфейса найдем мутации создания
    //это нужно чтобы найти поля, участвующие в мутации.

    if (!type || !type.possibleTypes) return null;

    type.possibleTypes.forEach(item => {
      const mutation = this.getMutation(item.name);
      if (mutation) {
        item.inputFields = mutation.inputFields
          .filter((field) => {
            if (field.name === "clientMutationId") {
              return false;
            }
            const f = type.fields.find(item => item.name === field.name);
            //exclude interfaces and objects field
            if (
              f &&
              (
                (f.type.kind === "INTERFACE" || f.type.kind === "OBJECT") ||
                (f.type.ofType && (f.type.ofType.kind === "INTERFACE" || f.type.ofType.kind === "OBJECT"))
              )
            ) {
              return false;
            }
            return true
          });
      }
    })

    return type.possibleTypes


  }

  public getDescriptionField(entityName: string, fieldName: string): string {
    let help: string = "";
    const entity = this.getEntityType(entityName)
    if (entity) {
      const fieldScheme = entity.inputFields.find(field => field.name === fieldName);
      if (fieldScheme) {
        help = fieldScheme.description || ""
      }
    }

    return help
  }

  public getInputFields(entityName: string, mutationType: MutationType, options: GenerateMutationOptions = {}) {
    const fields = this.getMutation(entityName, mutationType)!.inputFields;

    let variables = '';
    let inputVariables = '';

    fields
      .filter((field) => {
        if (options.excludeFields) {
          const excluded = !!options.excludeFields.find(excludeItem => excludeItem === field.name);
          if (excluded) {
            return false;
          }
        }
        return true;
      })
      .forEach((field) => {
        let required = field.type.kind === "NON_NULL" ? "!" : "";
        const list = field.type.kind === "LIST"
        let typeName = field.type.name ? field.type.name : field.type.ofType ? field.type.ofType.name : "";
        if (list) {
          typeName = `[${typeName}${required}]` as any
          required = ""
        }
        variables += `$${field.name}: ${typeName}${required},`;
        inputVariables += `${field.name}:$${field.name},`;

      })

      return {
        variables,
        inputVariables,
      };
  }

  public generateMutation(entity: EntityType, mutationType: MutationType, options: GenerateMutationOptions = {}) {
    const mutationName = `${mutationType}${entity.name}`;
    let returnedValues = '';

    entity.fields.filter((field) => {

      if (options.excludeFields) {
        const excluded = !!options.excludeFields.find(excludeItem => excludeItem === field.name);
        if (excluded) {
          return false;
        }
      }
      return true;
    }).forEach((field) => {

      const isObject = (field.type && (field.type.kind === "OBJECT" || field.type.kind === "INTERFACE")) ||
        (field.type.ofType && (field.type.ofType.kind === "OBJECT" || field.type.ofType.kind === "INTERFACE"))

      if (isObject) {
        return
      }
      if (options.excludePayloadFields) {
        const excluded = !!options.excludePayloadFields.find(excludeItem => excludeItem === field.name || excludeItem === '*' && field.name !== 'id');
        if (!excluded) {
          returnedValues += `${field.name},`
        }
      } else {
        returnedValues += `${field.name},`
      }

    })

    const { inputVariables, variables } = this.getInputFields(entity.name, mutationType, options);

    return gql`mutation ${mutationName}(${variables}){
        ${mutationName}(
            input: {
                ${inputVariables}
            }
        ){
            ${lcFirst(entity.name)}{
                ${returnedValues}
            }
        }
    }`
  };

  public getInterfacesFieldsFragments(_interface: string) {

    const possibleTypes = this.getPossibleTypes(_interface);

    let result = ""
    if (possibleTypes) {
      possibleTypes.forEach(item => {
        const fields = item.inputFields.reduce<string>((prev, current) => prev += `${current.name},\n`, '');
        if(!item.inputFields.length) return;
        result += `
          ... on ${item.name}{
            ${fields}
          }
        `
      })
      //console.log(result)
      return result
    }
    return result
  }

}


