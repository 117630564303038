import * as React from "react";
import useAxios, { makeUseAxios, UseAxios, Options } from 'axios-hooks'
import api from "../customFetch"
import { AxiosRequestConfig } from "axios";
import { NetworkContext } from "../components/network/NetworkContext";

export default function<T>(config: AxiosRequestConfig | string, options?: Options){
    const networkContext = React.useContext(NetworkContext)
    return makeUseAxios({
        axios: networkContext.request?.fetch
      })<T>(config, options)
}  