import * as React from "react";
import { Result, Button } from "antd";

const ErrorPage: React.FC = () => {
    return (
        <Result
            //@ts-ignore
            status={"500"}
            title="Произошла ошибка"
            subTitle="Мы уже исправляем проблему."
            extra={<Button 
                type="primary"
                onClick={()=>{
                    window.location.replace("/");
                }}
            >На главную</Button>}
        />
    )
}
export default ErrorPage