import * as React from "react";

import { Layout, Typography, Row, Col } from "antd";
import View from "./View";
import classNames from "classnames";
import useWindowSize from "../hooks/useWindowSize";
import Logo from "./Logo";
import SelectorLanguage from "./SelectorLanguage";
import { setNativeProps } from "../Utils";

type PageProps = {
    title?: string,
    header?: any,
    mobileMenu?: React.ReactNode,
    navigarion?: () => React.ReactNode,
    footer?: React.ReactNode,
    disabledLang?: boolean
}

const { Content, Header, Footer, Sider } = Layout;
const initTop = 120;
let lastPageScrollOffsetY = 0;
let lastVector = "bottom";
let lastChangeVectorOffset = 0;
const Page: React.FC<PageProps> = ({ title, children, header, navigarion, footer, disabledLang, mobileMenu }) => {

    const { isMobile, height } = useWindowSize();

    React.useEffect(() => {
        window.document.title = title || "Aqua Delivery"
    }, [])


    const onScroll = () => {
        if (!isMobile) {

            const offsetY = window.pageYOffset;
            const vector = offsetY > lastPageScrollOffsetY ? "bottom" : "top";

            if (vector !== lastVector) { lastChangeVectorOffset = offsetY }

            setNativeProps("navigation-menu-absolute", (navAbsoluteElem) => {
                if (
                    offsetY > navAbsoluteElem.clientHeight &&
                    lastVector === "bottom" &&
                    vector === "top"
                ) {
                    navAbsoluteElem.style.top = `${offsetY - navAbsoluteElem.clientHeight}px`
                }

                setNativeProps("navigation-menu-fixed", (elem) => {
                    if (vector === "bottom" && lastVector === "top") {
                        elem.style.display = "none";
                        navAbsoluteElem.style.display = "block";
                        navAbsoluteElem.style.top = `${offsetY}px`
                    } else if (lastChangeVectorOffset - offsetY >= navAbsoluteElem.clientHeight) {
                        navAbsoluteElem.style.display = "none";
                        elem.style.display = "block";
                    }
                })
            })

            lastVector = vector;
            lastPageScrollOffsetY = offsetY;

        }
    }


    React.useEffect(() => {


    }, [height])

    React.useEffect(() => {

        function watchScroll() {
            window.addEventListener("scroll", onScroll);
        }
        watchScroll();
        // Remove listener (like componentWillUnmount)
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);


    return (
        <Layout>

            <Header style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
                <Col xs={1} md={2}></Col>
                <Col xs={22} sm={22} md={20} lg={20}>
                    <View flexDirection="row" alignItems="center">
                        <Logo />
                        {
                            isMobile &&
                            (
                                !mobileMenu || (!mobileMenu && !disabledLang)
                                    ? <SelectorLanguage />
                                    : (
                                        <View flex={1} flexDirection="row" alignItems="center" justifyContent='flex-end'>
                                            {mobileMenu}
                                        </View>
                                    )
                            )
                        }
                        {
                            !!header && <View className="hidden-sm" flex={1} flexDirection="row" alignItems="center" justifyContent='flex-end'>
                                {header}
                            </View>
                        }
                    </View>
                </Col>
                <Col xs={1} md={2}></Col>
            </Header>
            <Content
                style={{ marginTop: 71 }}
            >

                <Row>
                    <Col xs={1} md={2} style={{ pointerEvents: 'none' }}></Col>
                    {
                        !isMobile &&
                        <Col xs={0} sm={0} md={20} lg={20}>
                            <Layout hasSider>
                                {
                                    !!navigarion &&
                                    <Sider id="navigation-menu-absolute" style={{ position: "absolute" }} width={100}>
                                        {navigarion()}
                                    </Sider>
                                }
                                {
                                    !!navigarion &&
                                    <Sider id="navigation-menu-fixed" style={{ position: "fixed", top: initTop, display: "none" }} width={100}>
                                        {navigarion()}
                                    </Sider>
                                }
                                <Row style={{ 
                                    flex: 1,
                                    marginLeft: 100,
                                    minWidth: 0,
                                    position: 'relative'
                                }}>
                                    <Col xs={24}>
                                        {
                                            !!title &&
                                            <Typography.Title className={"header-page"}>
                                                {title}
                                            </Typography.Title>
                                        }
                                        <div className={classNames("main-container", { ["with-navigation"]: !!navigarion })}>
                                            {children}
                                        </div>
                                    </Col>
                                </Row>

                            </Layout>
                        </Col>
                    }

                    {
                        isMobile &&

                        <Col xs={22} sm={22} md={0} lg={0}>
                            <Row style={{ flex: 1, minWidth: 0 }}>
                                <Col xs={24}>
                                    {
                                        !!title &&
                                        <Typography.Title className={"header-page"}>
                                            {title}
                                        </Typography.Title>
                                    }
                                    <div className={"main-container"}>
                                        {children}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    }

                    <Col xs={1} md={2} style={{ pointerEvents: 'none' }}></Col>
                </Row>


            </Content>

            <Footer>
                <Row>
                    <Col xs={1} md={2}></Col>
                    {
                        (!isMobile || !navigarion) &&
                        <Col xs={22} sm={22} md={20} lg={20}>
                            <Row gutter={{ sm: 12 }} align="middle" justify="end">
                                <Col>{footer}</Col>
                                {!disabledLang && <Col><SelectorLanguage /></Col>}
                                <Col> &#169;{` Aqua Delivery ${(new Date()).getFullYear()}`}</Col>
                            </Row>
                        </Col>
                    }
                    {
                        !!navigarion &&
                        isMobile &&
                        <Col xs={22} sm={22} md={0} lg={0}>
                            {navigarion()}
                        </Col>
                    }
                    <Col xs={1} md={2}></Col>
                </Row>

            </Footer>
            {/* <Drawer
                style={{ position: 'absolute' }}
                onClose={() => setDrawerState(false)}
                visible={openDrawer}
                placement="right"
                closable
                destroyOnClose
            >
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Drawer> */}

        </Layout>
    )
}

export default Page