import React from "react";
import { Result, Button } from "antd";

const Page404: React.FC = () => {
    return (
        <Result
            //@ts-ignore
            status={"404"}
            title="404"
            subTitle="Извините, запрашиваемая страница не существует."
            extra={<Button 
                type="primary"
                onClick={()=>{
                    window.location.replace("/");
                }}
            >На главную</Button>}
        />
    )
}
export default Page404