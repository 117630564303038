import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ErrorPage from "./static-pages/ErrorPage";

type Props = RouteComponentProps<{}>

type State = {
    hasError: boolean
}
class ErrorBoundary extends React.PureComponent<Props, State>{
    state: State = {
        hasError: false
    }
    pathname?: string;
    componentDidMount() {
        this.pathname = this.props.history.location.pathname;
    }
    componentDidUpdate(prevProps: Props) {
        const { history } = this.props;
        if (history.location.pathname !== this.pathname) {
            this.setState({ hasError: false });
            this.pathname = history.location.pathname;
        }
    }
    componentDidCatch(e: any) {
        console.log(e)
        this.setState({ hasError: true })
    }
    render() {
       
        if (this.state.hasError) {
            return (
                <ErrorPage/>
            )
        }
        return this.props.children
    }
}

export default withRouter(ErrorBoundary)