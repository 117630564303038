export default {
    ui: {
        selectorLang: {
            label: "Language"
        }
    },
    payPage: {
        statuses: {
            success: "Success",
        },
        successPayment: "Service {{serviceName}} is paid",
        goToCabinet: "Go to cabinet",
        pageTitle: "Payment for service",
        main: {
            particles: {
                weAreGetYourRequest: "We have received a request from you",
                forService: "for service «{{serviceTitle}}»",
                servicePrice: "Service cost",
            }
        },
        pay: "Pay",
    },
}