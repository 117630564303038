export default {
    copied: 'Скопировано',
    fileField: {
        label: "Нажмите или перетащите файл в область, чтобы загрузить файл",
        multipleLabel: "Нажмите или перетащите файлы в область, чтобы загрузить файлы",
        multipleFilesLabel: "Вы можете выбрать как один, так и несколько файлов"
    },
    autoCompleteField:{
        placeholder: "Введите местоположение"
    },
    daysField: {
        save: "Сохранить",
        weekdays: "Будни",
        weekends: "Выходные"
    },
    selectorLang: {
        label: "Язык"
    },
    cloudPaymentsButton: {
        label: 'Банковская карта',
    },
    noData: 'Нет данных',
    countryPicker: {
        placeholder: {
            loading: 'Загрузка...',
            selectCountry: 'Выберите вашу страну',
        },
    },
    checkboxSelector: {
        checkAll: 'Выбрать все',
        ok: 'Ок',
    },
    technicalWorkPage: {
        title: 'Ведутся технические работы',
        subtitle: 'Зайдите через полчасика, мы все починим и наладим',
    },
}