import React from "react";

import { useRouteMatch } from "react-router-dom";
import { Typography, Button, Spin, Row, Col, Result, message } from "antd";
import { t } from 'i18n-js';

import Page from "../../aqua-delivery-web-client-ui/components/Page";
import Block from "../../aqua-delivery-web-client-ui/components/Block";
import useRequest from "../../aqua-delivery-web-client-ui/hooks/useRequest";
import { config } from "../../config";
import InvoiceEntity from "../../aqua-delivery-client-graphql/invoice/entities/InvoiceEntity";
import { withCurrency, parseUuid, validationErrors } from "../../aqua-delivery-client-graphql/utils";
import { translateMap } from "../../translations";
import { I18nContext } from "../../aqua-delivery-web-client-ui/i18n";
import { CloudPaymentsButtonProps } from "../../aqua-delivery-web-client-ui/components/payment-form/components/payment-buttons/CloudPaymentsButton";
import { PayPalButtonProps } from "../../aqua-delivery-web-client-ui/components/payment-form/components/payment-buttons/PayPalButton";
import PaymentButtons from "../../aqua-delivery-web-client-ui/components/payment-form/components/PaymentButtons";

import './styles/styles.less'

const tMap = translateMap.payPage;

type Invoice = {
    total: number
    currency: string
    memo: string
    title: string,
    partnerId: string
}

const PayPage: React.FC = () => {
    const [payed, setPayedState] = React.useState(false)
    const { locale } = React.useContext(I18nContext);
    const match = useRouteMatch<{ invoice: string }>();
    const invoiceId = match.params.invoice;

    const [{ data, loading, error }] = useRequest<Invoice>({
        url: config.api.invoice.invoiceDetails,
        method: 'post',
        data: {
            invoice: InvoiceEntity.createId(invoiceId)
        }
    })

    const paymentData = React.useMemo(() => {
        if (!data) return;

        return {
            price: data.total,
            selectedCurrency: data.currency,
            paymentDescription: data.title,
            partnerId: data.partnerId,
            quantity: 1,
        };
    }, [data]);

    const cloudPaymentsProps: CloudPaymentsButtonProps | undefined = React.useMemo(() => {
        if (!paymentData) return undefined;

        const {
            price,
            selectedCurrency,
            paymentDescription,
            partnerId,
            quantity,
        } = paymentData;

        return {
            chargeInfo: {
                currency: selectedCurrency,
                accountId: parseUuid(partnerId),
                description: paymentDescription,
                publicId: config.cloudPaymentsPublicId,
                requireEmail: true,
                invoiceId,
            },
            language: locale,
            email: 'noreply@test.ru',
            items: [
                {
                    label: paymentDescription,
                    price: price,
                    quantity,
                    amount: price * quantity,
                    vat: 0,
                    method: 0,
                    object: 0,
                },
            ],
            onSuccess: async function () {
                try {
                    setPayedState(true)
                } catch (e) {
                    return validationErrors(e) || message.error(e.message);
                }
            },
            onError: async function (reason) {
                reason && message.error(reason.toString());
            },
        };
    }, [invoiceId, locale, paymentData]);

    const payPalProps: PayPalButtonProps | undefined = React.useMemo(() => {
        if (!paymentData) return undefined;

        const { price, selectedCurrency, paymentDescription, partnerId } = paymentData;

        return {
            currency: selectedCurrency,
            clientId: config.payPalClientId,
            createOrder: (_, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                value: price,
                            },
                            custom_id: parseUuid(partnerId),
                            description: paymentDescription,
                        },
                    ],
                });
            },
            onApprove: async (_, actions) => {
                try {
                    await actions.order.capture();

                    setPayedState(true);
                } catch (e) {
                    return validationErrors(e) || message.error(e.message);
                }
            },
            onError: async (error: any) => {
                message.error(error.toString());
            },
        };
    }, [paymentData]);

    if (error) {
        return (
            <Result
                status="404"
                title={error.response && error.response.data && error.response.data["hydra:description"]? error.response.data["hydra:description"] : error.message}
            />
        )
    }

    if (payed) {
        return (
            <Result
                status="success"
                title={t(tMap.statuses.success)}
                subTitle={t(tMap.successPayment, { serviceName: data.title })}
                extra={[
                    <Button
                        type="primary"
                        key="console"
                        onClick={() => window.location.replace(config.partnerWebclientUrl)}
                    >
                        {t(tMap.goToCabinet)}
                    </Button>
                ]}
            />
        )
    }

    return (

        <Page
            title={t(tMap.pageTitle)}
        >
            <Spin spinning={loading}>
                {
                    !!data &&
                    <>
                        <Typography.Title className="primary" style={{ marginBottom: 31 }}>{data.title}</Typography.Title>
                        <Row>
                            <Col style={{ width: '100%' }}>
                                <Block style={{ padding: '44px' }} className="pay-block" primary>
                                    <Typography.Paragraph style={{ fontSize: 18, marginBottom: 0 }} className="pay-title">{t(tMap.main.particles.weAreGetYourRequest)}</Typography.Paragraph>
                                    <Typography.Paragraph style={{ fontSize: 18 }} className="pay-title">{t(tMap.main.particles.forService, { serviceTitle: data.title })}</Typography.Paragraph>
                                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                                        <Typography.Text style={{ fontSize: 18 }}>{t(tMap.main.particles.servicePrice)}</Typography.Text>
                                        <br />
                                        <Typography.Text style={{ fontSize: 28 }} strong>{withCurrency(data.total, data.currency)}</Typography.Text>
                                    </div>

                                    <PaymentButtons
                                        cloudPaymentsProps={cloudPaymentsProps}
                                        payPalProps={payPalProps}
                                        paymentMethodsAvailability={config.paymentMethodsAvailability}
                                    />
                                </Block>
                            </Col>
                        </Row>
                    </>
                }

            </Spin>

        </Page>
    )
}
export default PayPage