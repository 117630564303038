export default {
    copied: 'Copied',
    fileField: {
        label: "Click or drag file to the area to upload file",
        multipleLabel: "Click or drag files to the area to upload files",
        multipleFilesLabel: "You can choose one or more files"
    },
    autoCompleteField: {
        placeholder: "Enter your location"
    },
    daysField: {
        save: "Save",
        weekdays: "Weekdays",
        weekends: "Weekends"
    },
    selectorLang: {
        label: "Language"
    },
    cloudPaymentsButton: {
        label: 'Bank card',
    },
    noData: 'No data',
    countryPicker: {
        placeholder: {
            loading: 'Loading...',
            selectCountry: 'Select your country',
        },
    },
    checkboxSelector: {
        checkAll: 'Check all',
        ok: 'Ok',
    },
    technicalWorkPage: {
        title: 'Technical work is underway',
        subtitle: 'Come back in half an hour, we\'ll do all we need',
    },
}