import Currency from '../../../CurrencyHelper';

export type UsePayPal = {
    currency?: string;
    checkCurrency: boolean;
};

const usePayPal = ({ currency, checkCurrency }: UsePayPal) => {
    const isRuble = Currency.isRuble(currency);
    const isSuitableCurrency = !checkCurrency || (checkCurrency && !isRuble);

    return {
        isSuitableCurrency,
    };
};

export default usePayPal;
