import Currency from '../../../CurrencyHelper';

export type UseCloudPayments = {
    currency?: string;
    checkCurrency: boolean;
};

const useCloudPayments = ({ currency, checkCurrency }: UseCloudPayments) => {
    const isRuble = Currency.isRuble(currency);
    const isSuitableCurrency = !checkCurrency || (checkCurrency && isRuble);

    return {
        isSuitableCurrency,
    };
};

export default useCloudPayments;
