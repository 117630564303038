import React from 'react';

import Icon from '@ant-design/icons/es/components/Icon';

import LogoSvg from '../assets/svg/aqua-delivery-logo.svg';
import SVG from './SVG';

const LogoIcon: React.FC = () => <SVG source={LogoSvg} width={71} height={71} strokeWidth={0} />;

const Logo: React.FC = () => {
    return <Icon style={{ width: 71, height: 71 }} component={LogoIcon} />;
};

export default Logo;
