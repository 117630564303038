import { gql } from "apollo-boost";
import { BaseEntity } from "../../types";

export enum InvoiceState {
    created = "invoice_created",
    paid = "invoice_paid",
    archived = "invoice_archived"
}

export declare type TInvoice = BaseEntity<{
    total: number
    memo: string
    title: string
    number: number,
    state: InvoiceState,
    createdAt: string,
    type: string //TODO: убрать когда появиться поле на бэкенде
}>


const fragment = gql`
fragment Invoice on Invoice{
    id,
    total,
    memo,
    title,
    number,
    state,
    createdAt
}
`;

const InvoiceEntity = {
    fragment,
    createId: (id: string)=> `/invoice/invoices/${id}`,
    stateToStr: (state: InvoiceState)=>{
        switch(state){
            case InvoiceState.created: return "Новый";
            case InvoiceState.paid: return "Оплачен";
            case InvoiceState.archived: return "Заархивирован";
            default: return "Неизвестый статус"
        }
    }
};

export default InvoiceEntity