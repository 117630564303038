import * as React from 'react';
import classNames from 'classnames';

type Props = {
    primary?: boolean;
    secondary?: boolean;
    className?: string;
    ContainerProps?: React.HTMLAttributes<HTMLDivElement>;
    style?: any;
    disabled?: boolean;
    square?: boolean;
};

const Block: React.FC<Props> = ({
    children,
    ContainerProps,
    primary,
    secondary,
    className,
    style,
    disabled,
    square,
}) => {
    return (
        <div
            style={style}
            {...ContainerProps}
            className={classNames('block', className, {
                'block-primary': primary,
                'block-secondary': secondary,
                'block-disabled': disabled,
                square: square,
            })}
        >
            {children}
        </div>
    );
};

export default Block;
